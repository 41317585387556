import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { User } from '@/types';

export const getMe = async (): Promise<User> => {
  return axios.get(`/whoami`);
};

type QueryFnType = typeof getMe;

type UseLocationOptions = {
  locationId?: number;
  config?: QueryConfig<QueryFnType>;
};

export const useMe = ({ config }: UseLocationOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['me'],
    queryFn: () => getMe(),
  });
};
