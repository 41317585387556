import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { GalleryVerticalEnd } from 'lucide-react';
import { getLabelValue } from '@/utils/getLabelValue';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useSelections } from '@/api/use-selections';
import { useSettingsStore } from '@/stores/settingsStore';
import { useEffect, useRef, useState } from 'react';
import { processSelection } from '@/api/process-selection';
import { useOrderList } from '@/api/useOrderList';
import { Progress } from '@/components/ui/progress';
import { cn } from '@/utils';
import { usePassage } from '@/hooks/use-passage';

export function BatchPrintDialog() {
  const [selection, setSelection] = useState<string | undefined>(undefined);
  const [printQueue, setPrintQueue] = useState<string[]>([]);
  const [progress, setProgress] = useState<number>(0);
  const [isPrinting, setIsPrinting] = useState<boolean>(false);
  const cancelRef = useRef(false);

  const eventId = useSettingsStore(state => state.eventId) ?? 0;

  const { pass } = usePassage();

  const { data: selections } = useSelections({ eventId: eventId, config: { enabled: !!eventId } });
  const { data: orders } = useOrderList({ eventId, config: { enabled: !!eventId } });

  useEffect(() => {
    if (selection) {
      (async () => {
        const ordersIds = await processSelection(eventId, parseInt(selection));
        const ordersToPrint = orders?.filter(order => ordersIds.includes(order.orderId));
        setPrintQueue(ordersToPrint?.map(order => order.badgeNo) ?? []);
        setIsPrinting(false);
        setProgress(0);
      })();
    }
  }, [selection]);

  const printSelections = async () => {
    if (printQueue.length === 0) return;

    setIsPrinting(true);
    let progressIncrement = 100 / printQueue.length;
    let progress = 0;

    for (let badgeNo of printQueue) {
      if (cancelRef.current) break; // Check for cancellation request

      try {
        debugger;
        setProgress((progress += progressIncrement));
        await pass(badgeNo); // Assuming checkin returns a promise
        await new Promise(resolve => setTimeout(resolve, 1500)); // Simulate async operation
      } catch (error) {
        console.error(error);
        alert('An error occurred during printing.');
        break; // Stop printing on error
      }
    }

    setIsPrinting(false);
    setProgress(0); // Reset progress after completion or cancellation
  };

  const cancelPrint = () => (cancelRef.current = true); // Set flag to true to indicate cancellation

  useEffect(() => {
    // Reset cancel state when printing stops
    if (!isPrinting) {
      cancelRef.current = false;
    }
    return () => {
      cancelRef.current = false;
    };
  }, [isPrinting]);

  return (
    <Dialog>
      <DialogTrigger className='w-full'>
        <Button variant="outline" className="w-full" size="sm">
          <GalleryVerticalEnd className="h-4 w-4 mr-1" /> {getLabelValue('BatchPrint')}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="mb-4">{getLabelValue('BatchPrintDialogTitle')}</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <div className="flex items-start flex-col space-y-4 ">
            <Label className="w-24"> {getLabelValue('Selections')}</Label>
            <Select onValueChange={value => setSelection(value)} value={selection}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="-" />
              </SelectTrigger>
              <SelectContent>
                {selections?.map(selection => (
                  <SelectItem key={selection.selectionId} value={selection.selectionId?.toString()}>
                    {selection.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          {isPrinting && (
            <div>
              <div className="relative">
                <span className={cn('absolute z-10 top-0 left-1/2 -translate-x-1/2 font-semibold ', progress < 47 ? 'text-black dark:text-white' : 'text-white')}>
                  {progress.toFixed(0)}%
                </span>
                <Progress className="h-6" value={progress} />
              </div>
            </div>
          )}
          {selection && (
            <div className="text-sm">
              {printQueue.length} {getLabelValue('OrdersToPrint')}
            </div>
          )}
        </div>

        <DialogFooter className="flex justify-start">
          {isPrinting ? (
            <Button variant="destructive" onClick={cancelPrint}>
              {getLabelValue('Cancel')}
            </Button>
          ) : (
            <Button disabled={printQueue.length === 0} onClick={printSelections}>
              {getLabelValue('Print')}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
