import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { DownloadCloudIcon, Settings, Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';
import { useSettingsStore } from '@/stores/settingsStore';
import { useEvents } from '@/api/useEvents';
import { Input } from '@/components/ui/input';
import { DeleteButton } from '@/components/delete-button';
import { getLabelValue } from '@/utils/getLabelValue';
import { clearCheckInData } from '@/api/clearCheckInData';
import { useToast } from '@/components/ui/use-toast';
import { ThemeSwitcher } from './theme-switcher';
import storage from '@/utils/storage';

export function SettingsDialog() {
  const setDirection = useSettingsStore(state => state.setDirection);
  const setAllowReEntry = useSettingsStore(state => state.setAllowReEntry);
  const setAlwaysPrint = useSettingsStore(state => state.setAlwaysPrint);
  const setBreakoutId = useSettingsStore(state => state.setBreakoutId);
  const setPrinter = useSettingsStore(state => state.setPrinter);
  const setOnlyBooked = useSettingsStore(state => state.setOnlyBooked);

  const { toast } = useToast();

  const direction = useSettingsStore(state => state.direction);
  const breakoutId = useSettingsStore(state => state.breakoutId);
  const allowReEntry = useSettingsStore(state => state.allowReEntry);
  const alwaysPrint = useSettingsStore(state => state.alwaysPrint);
  const printer = useSettingsStore(state => state.printer);
  const onlyBooked = useSettingsStore(state => state.onlyBooked);
  const printers = useSettingsStore(state => state.printers);
  const eventId = useSettingsStore(state => state.eventId);

  // const handleClearCheckInData = async () => {
  //   try {
  //     if (eventId && eventId > 0) {
  //       await clearCheckInData(eventId);

  //       toast({ title: getLabelValue('OnsiteStatisticsCleared'), variant: 'success' });
  //     }
  //   } catch (error) {
  //     toast({ title: getLabelValue('FailedToClearOnsiteStatistics'), variant: 'destructive' });
  //   }
  // };

  // const { data: events } = useEvents({
  //   eventId: eventId ?? 0,
  //   config: {},
  // });

  return (
    <Dialog>
      <DialogTrigger>
        <Button variant="outline">
          <Settings className="h-4 w-4 mr-1" /> {getLabelValue('SettingsModalTitle')}
        </Button>
      </DialogTrigger>
      <DialogContent className="bg-card">
        <DialogHeader>
          <DialogTitle className="mb-4">{getLabelValue('SettingsModalTitle')}</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <div className="grid gap-2">
            <div className="flex flex-row items-start space-x-3 space-y-0 rounded-md border text-sm p-4">
              <Switch checked={alwaysPrint} onCheckedChange={setAlwaysPrint} />
              <div className="space-y-1 leading-none">
                <label className="font-semibold">{getLabelValue('AlwaysPrintOnScan')}</label>
                <p className="text-muted-foreground">{getLabelValue('AlwaysPrintOnScanDescription')}</p>
              </div>
            </div>
          </div>
          <div className="grid gap-2">
            <div className="flex flex-row items-start space-x-3 space-y-0 rounded-md border text-sm p-4">
              <Switch checked={onlyBooked} onCheckedChange={setOnlyBooked} />
              <div className="space-y-1 leading-none">
                <label className="font-semibold">{getLabelValue('OnlyBooked')}</label>
                <p className="text-muted-foreground">{getLabelValue('OnlyBookedDescription')}</p>
              </div>
            </div>
          </div>
          <div className="grid gap-2">
            <div className="flex flex-row items-start space-x-3 space-y-0 rounded-md border text-sm p-4">
              <Switch checked={allowReEntry} onCheckedChange={setAllowReEntry} />
              <div className="space-y-1 leading-none">
                <label className="font-semibold">{getLabelValue('AllowReentry')}</label>
                <p className="text-muted-foreground">{getLabelValue('AllowReentryDescription')}</p>
              </div>
            </div>
          </div>

          <div className="grid gap-2">
            <div className="flex flex-row items-start justify-between rounded-md border text-sm p-4">
              <div className="space-y-1 leading-none">
                <label className="font-semibold">{getLabelValue('Theme')}</label>
                <p className="text-muted-foreground">{getLabelValue('ThemeDescription')}</p>
              </div>
              <ThemeSwitcher />
            </div>
          </div>
          <div className="border p-6 space-y-4 rounded-md">
            {/* <div className="flex items-center space-x-2">
              <Label className="w-24"> {getLabelValue('Breakout')}</Label>
              <Select onValueChange={value => setBreakoutId(Number(value))} value={breakoutId?.toString()}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="-" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value={eventId?.toString() ?? ''}>{getLabelValue('MainEntrance')}</SelectItem>
                  {events
                    ?.filter(event => event.eventId !== eventId)
                    ?.map(event => (
                      <SelectItem key={event.eventId} value={event.eventId.toString()}>
                        {event.name}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
            </div>
            <div className="flex items-center space-x-2">
              <Label className="w-24"> {getLabelValue('Printer')}</Label>
              <Select onValueChange={value => setPrinter(printers?.find(printer => printer.clientGuid === value) ?? null)} value={printer?.clientGuid}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="-" />
                </SelectTrigger>
                <SelectContent>
                  {printers?.map(printer => (
                    <SelectItem key={printer.clientGuid} value={printer.clientGuid}>
                      {printer.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div> */}

            <div className="flex flex-col gap-4 ">
              <Label className="w-24"> {getLabelValue('Location')}</Label>
              <div>
                <Input value={storage.getDeviceId()?.toString()}  readOnly disabled/>
              </div>
            </div>
          </div>
        </div>
        <DialogFooter className="flex flex-col w-full justify-start">
          <div className="flex flex-col w-full space-y-2">
            <a
              href="https://jirangopub.s3.eu-north-1.amazonaws.com/PrintClient/BadgePrinterV2.exe"
              download=""
              className="italic text-sm underline w-full"
              title="Ladda ner utskriftsprogram"
            >
              <Button variant="outline" className="w-full">
                <DownloadCloudIcon className="h-4 w-4 mr-1" />
                {getLabelValue('DownloadPrintProgram')}
              </Button>
            </a>
            {/* <DeleteButton
              onDelete={handleClearCheckInData}
              ButtonComponent={
                <Button variant="destructive" className="w-full">
                  <Trash2 className="h-4 w-4 mr-1" />
                  {getLabelValue('ClearOnsiteStatistics')}
                </Button>
              }
            /> */}
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
