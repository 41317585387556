import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';

import storage from '@/utils/storage';

export type LoginCredentialsDTO = {
  username: string;
  password: string;
};

export const login = async (data: LoginCredentialsDTO) => {
  try {
    const token = (await axios.post('/login' + window.location.search, data)) as string;

    if (!token) throw new Error('No token found');

    storage.setToken(token);

    queryClient.refetchQueries('me');

  } catch (error) {
    throw error;
  }
};
