import { useQuery } from 'react-query';
import { Selection } from '@/types';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getSelections = (eventId: number): Promise<Selection[]> => {
  return axios.get(`/${eventId}/selection`);
};

type QueryFnType = typeof getSelections;

type UseOptions = {
  eventId: number;
  config?: QueryConfig<QueryFnType>;
};

export const useSelections = ({ config, eventId }: UseOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['selections', eventId],
    queryFn: () => getSelections(eventId),
    ...config,
  });
};
