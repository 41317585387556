import uuid4 from 'uuid4';

const APP_PREFIX = `jirango_onsite_`;

const storage = {
  setDeviceId: (id?: string) => {
    window.localStorage.setItem(`${APP_PREFIX}deviceId`, id ?? uuid4());
  },
  getDeviceId: () => {
    return window.localStorage.getItem(`${APP_PREFIX}deviceId`);
  },
  getToken: () => {
    return JSON.parse(window.localStorage.getItem(`${APP_PREFIX}token`) as string);
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${APP_PREFIX}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${APP_PREFIX}token`);
  },
};

export default storage;
