import { Printer } from '@/types';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import uuid4 from 'uuid4';

type SettingsStore = {
  printer?: Printer | null;
  printers: Printer[];
  alwaysPrint: boolean;
  breakoutId: number | null;
  direction: string;
  allowReEntry: boolean;
  onlyBooked: boolean;
  token: string | null;
  credentials: {
    username: string;
    token: string;
    userGuid: string;
  };
  theme: 'light' | 'dark';
  eventId: number | null;
  location: string;
  languageCode: string | null;
  searchInputRef: null | HTMLInputElement;
  extendedParticipation: boolean;
  hideConnectionLoss: boolean;
  expandedStats: boolean;
  connected: boolean;
  setExpandedStats(expandedStats: boolean): void;
  setTheme: (theme: 'light' | 'dark') => void;
  setHideConnectionLoss: (hideConnectionLoss: boolean) => void;
  setCredentials: (credentials: { username: string; token: string; userGuid: string }) => void;
  setLanguageCode: (languageCode: string | null) => void;
  setLocation: (location: string) => void;
  setBreakoutId: (breakoutId: number | null) => void;
  setEventId: (eventId: number | null) => void;
  setOnlyBooked: (onlyBooked: boolean) => void;
  setAllowReEntry: (allowReEntry: boolean) => void;
  setToken: (token: string | null) => void;
  setDirection: (direction: string) => void;
  setAlwaysPrint: (alwaysPrint: boolean) => void;
  setPrinter: (printer: Printer | null) => void;
  setPrinters: (printers: Printer[]) => void;
  setExtendedParticipation: (extendedParticipation: boolean) => void;
  setConnected: (connected: boolean) => void;
};

export const useSettingsStore = create(
  persist<SettingsStore>(
    set => ({
      connected: false,
      printer: null,
      printers: [],
      alwaysPrint: true,
      breakoutId: null,
      direction: 'In',
      allowReEntry: true,
      onlyBooked: true,
      token: null,
      languageCode: 'swe',
      extendedParticipation: false,
      eventId: null,
      location: uuid4(),
      hideConnectionLoss: false,
      credentials: {
        username: '',
        token: '',
        userGuid: '',
      },
      theme: 'light',
      searchInputRef: null,
      expandedStats: false,
      setConnected: (connected: boolean) => set({ connected }),
      setExpandedStats: (expandedStats: boolean) => set({ expandedStats }),
      setTheme: (theme: 'light' | 'dark') => set({ theme }),
      setCredentials: ({ username, token, userGuid }: { username: string; token: string; userGuid: string }) => set({ credentials: { username, userGuid, token } }),
      setLanguageCode: (languageCode: string | null) => set({ languageCode }),
      setLocation: (location: string) => set({ location }),
      setEventId: (eventId: number | null) => set({ eventId }),
      setToken: (token: string | null) => set({ token }),
      setDirection: (direction: string) => set({ direction }),
      setAllowReEntry: (allowReEntry: boolean) => set({ allowReEntry }),
      setOnlyBooked: (onlyBooked: boolean) => set({ onlyBooked }),
      setBreakoutId: (breakoutId: number | null) => set({ breakoutId }),
      setHideConnectionLoss: (hideConnectionLoss: boolean) => set({ hideConnectionLoss }),
      setAlwaysPrint: (alwaysPrint: boolean) => set({ alwaysPrint }),
      setPrinter: (printer: Printer | null) => set({ printer }),
      setPrinters: (printers: Printer[]) => set({ printers }),
      setExtendedParticipation: (extendedParticipation: boolean) => set({ extendedParticipation }),
    }),
    {
      name: 'settings-store',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
