import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/utils';

export function PassagePageSkeleton() {
  return (
    <>
      <div className="flex flex-col bg-background  h-screen overflow-hidden">
        <div className="flex justify-center ">
        </div>
        <main className="flex flex-col flex-1 ">
          <div className="h-auto">
            <div className="grid gap-4 md:grid-cols-5 max-w-7xl  lg:grid-cols-5 p-6 mx-auto">
              <Skeleton className="w-full h-18" />
              <Skeleton className="w-full h-18" />
              <Skeleton className="w-full h-18" />
              <Skeleton className="w-full h-18" />
              <Skeleton className="w-full h-18" />
            </div>
          </div>
          <div className="flex-1  bg-card border rounded-t-xl mx-0  w-full">
            <div className="flex flex-col pt-6 justify-start items-center w-full h-full relative space-y-4 max-w-7xl mx-auto">
              <div className={cn('space-y-4')}>
                <div className={cn('text-3xl flex justify-center w-full')}>
                  <Skeleton className="w-72 h-10" />
                </div>
                <div className="space-y-2 flex justify-center flex-col">
                  <div className="relative">
                    <Skeleton className="w-[500px] h-12 rounded-full" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
