import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getOrderList = (eventId: number): Promise<any[]> => {
  return axios.get(`/${eventId}/onsite`);
};

type QueryFnType = typeof getOrderList;

type UseOptions = {
  timestamp?: number;
  eventId: number;
  config?: QueryConfig<QueryFnType>;
};

export const useOrderList = ({ eventId, config }: UseOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['orderlist', eventId],
    queryFn: () => getOrderList(eventId),
    ...config,
  });
};
