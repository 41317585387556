import storage from '@/utils/storage';
import { useMe } from '../../auth/api/get-me';
import { LoginForm } from '@/features/auth/components/login-form';
import { Reader } from './qr-reader';
import { useEvents } from '@/api/useEvents';
import { Settings } from './settings';
import { Combobox } from '@/components/ui/combobox';
import { useSettingsStore } from '@/stores/settingsStore';
import { Spinner } from '@/components/ui/spinner';
import { SignalRContext } from '@/providers/SignalRProvider';
import { Menu } from './menu';

export function Checkin() {
  const currentEventId = useSettingsStore(state => state.eventId);
  const breakoutId = useSettingsStore(state => state.breakoutId);
  const setCurrentEventId = useSettingsStore(state => state.setEventId);
  const setBreakoutId = useSettingsStore(state => state.setBreakoutId);

  const { data: user, isLoading: isLoadingMe } = useMe({
    config: {
      enabled: !!storage.getToken(),
      refetchInterval: 1000 * 60 * 5, // 5 minutes,
      onSuccess: data => {
        if (data?.defaultEventId && !currentEventId) {
          setCurrentEventId(data.defaultEventId);
          setBreakoutId(data.defaultEventId);
        }
      },
    },
  });

  const { data: events = [], isLoading: isLoadingEvents } = useEvents({
    eventId: user?.defaultEventId ?? 0,
    config: {
      enabled: !!user?.defaultEventId,
    },
  });

  const handleEventChange = (eventId: number) => {
    SignalRContext.invoke('onsiteHandshake', {
      userGuid: user?.userGuid,
      eventId,
      ConnectionId: '123',
    });

    const event = events.find(event => event.eventId === eventId);

    if (event?.parentEventId) {
      setBreakoutId(eventId);
      setCurrentEventId(event.parentEventId);
    } else {
      setCurrentEventId(eventId);
      setBreakoutId(eventId);
    }
  };

  if (isLoadingMe || isLoadingEvents) {
    return (
      <div className="w-screen h-screen flex flex-col gap-4 items-center justify-center">
        <Spinner className="w-32 h-32" />
      </div>
    );
  }

  if (!user) {
    return <LoginForm />;
  }

  return (
    <div className="w-full h-screen ">
      <header className=" bg-white h-[60px] p-4 flex gap-2 items-center justify-between">
        <Menu />
        <Combobox
          className="bg-zinc-100 max-w-[230px] w-full"
          value={breakoutId ?? currentEventId}
          onChange={handleEventChange}
          options={events
            ?.sort((a, b) => ((a.parentEventId ?? a.eventId) > (b.parentEventId ?? b.eventId) ? 1 : -1))
            ?.map(event => ({
              prefix: event.eventId,
              label: `${event.eventId} - ${event.name}`,
              extra: event.parentEventId ? (
                <span className="text-xs text-accent-foreground ml-2 bg-accent p-1 px-1.5 rounded-md">
                  Aktivitet på event {events.find(e => e.eventId === event.parentEventId)?.name}
                </span>
              ) : null,
              group: event.parentEventId ?? event.eventId,
              value: event.eventId,
            }))}
        />
        <Settings />
      </header>
      <div className="w-full  h-[calc(100svh_-_60px)] ">
        <Reader />
      </div>
    </div>
  );
}
