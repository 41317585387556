import { useEffect, useState } from 'react';

export function useHasCameraPermission() {
  const [cameraAllowed, setCameraAllowed] = useState<boolean | null>(null);

  const checkCameraPermission = async () => {
    try {
      // Ask for camera access
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraAllowed(true);

      // Stop the stream if you don't need to display it
      stream.getTracks().forEach(track => track.stop());
    } catch (error) {
      // If permission is denied or other error occurs
      console.error('Error accessing the camera:', error);
      setCameraAllowed(false);
    }
  };
  useEffect(() => {
    checkCameraPermission();
  }, []);

  return cameraAllowed;
}
