import { Button } from '@/components/ui/button';
import { DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { useSettingsStore } from '@/stores/settingsStore';
import { DropdownMenu } from '@radix-ui/react-dropdown-menu';
import { Direction } from '../types/direction';
import { LogIn, LogOut } from 'lucide-react';
import { getLabelValue } from '@/utils/getLabelValue';

export function EntryDirectionDropdown() {
  const direction = useSettingsStore(state => state.direction);
  const setDirection = useSettingsStore(state => state.setDirection);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Button variant="outline">
          {direction === Direction.IN ? <LogIn className="h-4 w-4 mr-1 text-emerald-500" /> : <LogOut className="h-4 w-4 mr-1 text-orange-500" />}
          {direction === Direction.IN ? getLabelValue('CheckIn') : getLabelValue('CheckOut')}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>{getLabelValue('SelectDirection')}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuCheckboxItem checked={direction === Direction.IN} onClick={() => setDirection(Direction.IN)}>
          {getLabelValue('CheckIn')}
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem checked={direction === Direction.OUT} onClick={() => setDirection(Direction.OUT)}>
          {getLabelValue('CheckOut')}
        </DropdownMenuCheckboxItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
