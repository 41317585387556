import { useLabels } from '@/api/useLabels';
import { Header } from '../components/header';
import { OverviewCards } from '../components/overview-cards';
import { Search } from '../components/search';
import { useEvents } from '@/api/useEvents';
import { useEffect } from 'react';
import { useSettingsStore } from '@/stores/settingsStore';
import { SignalRContext } from '@/providers/SignalRProvider';
import { getLabelValue } from '@/utils/getLabelValue';
import storage from '@/utils/storage';
import { useMe } from '@/features/auth/api/get-me';
import { useToast } from '@/components/ui/use-toast';

export function PassingView() {
  const languageCode = useSettingsStore(state => state.languageCode) ?? 'swe';
  const eventId = useSettingsStore(state => state.eventId) ?? 0;

  const { toast } = useToast();

  const { data: user } = useMe({
    config: {
      enabled: !!storage.getToken(),
    },
  });

  const setEventId = useSettingsStore(state => state.setEventId);
  const setBreakoutId = useSettingsStore(state => state.setBreakoutId);
  const setLanguageCode = useSettingsStore(state => state.setLanguageCode);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const eventId = urlParams.get('eventId');
    const breakoutId = urlParams.get('breakoutId');
    const lang = urlParams.get('lang');

    setEventId(Number(eventId));
    setBreakoutId(Number(breakoutId ?? eventId));
    setLanguageCode(lang);
    // setCredentials('support@inadra.se', 'C9155037-1EB7-429C-8984-57E7BF51AC92');
  }, []);

  useLabels({
    eventId: Number(eventId),
    languageCode,
    config: {
      enabled: eventId > 0,
    },
  });

  useEvents({
    eventId: Number(eventId),
    config: {
      enabled: eventId > 0,
    },
  });

  useEffect(() => {
    if (SignalRContext.connection?.state !== 'Connected' || !user) return;
    (async () => {
      try {
        //Authenticate with signalR
        await SignalRContext.invoke('onsiteHandshake', {
          userGuid: user?.userGuid,
          eventId,
          ConnectionId: '123',
        });
      } catch (error) {
        // toast({ title: getLabelValue('FailedToAuthenticateWithSignalR'), variant: 'destructive' });
      }
    })();
  }, [SignalRContext.connection?.state, user?.userGuid]);

  return (
    <>
      {/* <ConnectionLossAlert /> */}
      <div className="flex flex-col bg-background  h-screen overflow-hidden">
        <Header />
        <main className="flex flex-col flex-1 ">
          <div className="h-auto">
            <OverviewCards />
          </div>
          <div className="flex-1  bg-card border rounded-t-xl mx-0 ">
            <div className="p-2 px-6 max-w-7xl mx-auto "></div>
            <Search />
          </div>
        </main>
      </div>
    </>
  );
}
