import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { AttendeeSearchResult } from '../types/attendee-search-result';

export const getAttendeeSearchResult = (eventId: number, searchKey: string): Promise<AttendeeSearchResult[]> => {
  return axios.get(`/${eventId}/onsite/search`, {
    params: {
      searchKey,
    },
  });
};

type QueryFnType = typeof getAttendeeSearchResult;

type UseOptions = {
  searchKey: string;
  eventId: number;
  config?: QueryConfig<QueryFnType>;
};

export const useAttendeeSearchResult = ({ eventId, searchKey, config }: UseOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['attendee_search_result', `${eventId}_${searchKey}`],
    queryFn: () => getAttendeeSearchResult(eventId, searchKey),
    ...config,
  });
};
