import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type BufferStore = {
  buffer: string[];
  processed: string[];
  setBuffer: (buffer: string[]) => void;
  setProcessed: (processed: string[]) => void;
};

export const useBufferStore = create(
  persist<BufferStore>(
    set => ({
      buffer: [],
      processed: [],
      setBuffer: (buffer: string[]) => set({ buffer }),
      setProcessed: (processed: string[]) => set({ processed }),
    }),
    {
      name: 'buffer-store',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
