import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getEvents = (eventId: number): Promise<any[]> => {
  return axios.get(`/${eventId}/event/all`);
};

type QueryFnType = typeof getEvents;

type UseEventsOptions = {
  eventId: number;
  config?: QueryConfig<QueryFnType>;
};

export const useEvents = ({ config, eventId }: UseEventsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['events', eventId],
    queryFn: () => getEvents(eventId),
    ...config,
  });
};
