import { SignalRContext } from '@/providers/SignalRProvider';
import { useBufferStore } from '@/stores/bufferStore';
import { useSettingsStore } from '@/stores/settingsStore';
import storage from '@/utils/storage';
import { useEffect, useState } from 'react';
import { useOnlineStatus } from './use-online-status';
import { useToast } from '@/components/ui/use-toast';
import { queryClient } from '@/lib/react-query';
import { OnsiteStats } from '@/features/desktop/types/onsite-stats';
import { useMe } from '@/features/auth/api/get-me';
import { getLabelValue } from '@/utils/getLabelValue';

export function usePassage() {
  const [passage, setPassage] = useState<{
    attendee: any;
    checkpointData: any;
  } | null>(null);

  const [proccessingBadgeNo, setProccessingBadgeNo] = useState<string | null>(null);

  const [error, setError] = useState<string | null>(null);

  const eventId = useSettingsStore(state => state.eventId) ?? 0;
  const breakoutId = useSettingsStore(state => state.breakoutId);
  const direction = useSettingsStore(state => state.direction);
  const allowReEntry = useSettingsStore(state => state.allowReEntry);
  const onlyBooked = useSettingsStore(state => state.onlyBooked);
  const alwaysPrint = useSettingsStore(state => state.alwaysPrint);
  const printer = useSettingsStore(state => state.printer);
  const buffer = useBufferStore(state => state.buffer);
  const processed = useBufferStore(state => state.processed);

  const { data: user } = useMe({
    config: {
      enabled: false,
    },
  });

  const setProcessed = useBufferStore(state => state.setProcessed);
  const setBuffer = useBufferStore(state => state.setBuffer);

  const isOnline = useOnlineStatus();
  const { toast } = useToast();

  const handleUpdateCache = (badgeNo: string, checkpointData: any) => {
    const key = breakoutId ? `${eventId}_${breakoutId}` : eventId;
    const updatedStats = queryClient.getQueryData<OnsiteStats>(['onsite_stats', key]);

    const hasPreviousPassage = updatedStats?.arrived?.[badgeNo];
    const hasPreviousExit = updatedStats?.exit?.[badgeNo];

    if (!updatedStats) {
      queryClient.invalidateQueries(['onsite_stats', key]);
      return;
    }

    // If checkpoint is an entrance
    if (checkpointData.direction === 1) {
      // If attendee has previous passage
      if (hasPreviousPassage) {
        // Add new passage to existing array
        updatedStats.arrived[badgeNo].push(checkpointData?.inserted);
      } else {
        // Create new array with passage
        // Remove attendee from not arrived
        // Add attendee to onsite
        updatedStats.arrived[badgeNo] = [checkpointData.inserted];
        updatedStats.notArrived = updatedStats.notArrived > 0 ? updatedStats.notArrived - 1 : 0;
      }

      updatedStats.onsite[badgeNo] = [checkpointData.inserted];
    } else if (checkpointData.direction === 2) {
      // If attendee has previous exit
      if (hasPreviousExit) {
        // Add new exit to existing array
        updatedStats.exit[badgeNo].push(checkpointData?.inserted);
      } else {
        // Create new array with exit
        updatedStats.exit[badgeNo] = [checkpointData.inserted];
      }

      // Remove attendee from onsite
      if (hasPreviousPassage) {
        delete updatedStats.onsite[badgeNo];
      }
    }

    queryClient.setQueryData(['onsite_stats', key], updatedStats);
  };

  const handlePass = async (badgeNo: string) => {
    setProccessingBadgeNo(badgeNo);

    if (SignalRContext.connection?.state !== 'Connected' || (!navigator.onLine && !buffer.includes(badgeNo))) {
      setBuffer([...buffer, badgeNo]);
      setProccessingBadgeNo(null);
      return;
    }

    if (alwaysPrint && !printer) {
      setProccessingBadgeNo(null);
      return toast({ title: 'Ingen skrivare ansluten!', variant: 'destructive' });
    }

    try {
      await SignalRContext.invoke('scan', {
        eventId: eventId,
        badgeNo,
        passingType: direction === 'In' ? 1 : 2,
        allowReEntry,
        printClient: printer?.clientGuid ?? null,
        breakoutId: breakoutId ?? null,
        onlyBooked,
        userId: user?.userId,
        timeStamp: +new Date(),
        add: true,
        scanDevice: storage.getDeviceId(),
        exhibitorId: 0,
        extendedParticipation: 0,
      });
    } catch (error) {
      setProccessingBadgeNo(null);
      console.error(error);
      toast({ title: 'Något gick fel!', variant: 'destructive' });
    }
  };

  SignalRContext.useSignalREffect(
    'onScanSuccess',
    async function onScanSuccess(badgeNo: string, checkpointData: any, attendee: any) {
      if (buffer.length > 0 && buffer.includes(badgeNo)) {
        setBuffer(buffer.filter(b => b !== badgeNo));
        setProcessed(processed.filter(b => b !== badgeNo));
      }

      handleUpdateCache(badgeNo, checkpointData);

      setPassage({
        attendee,
        checkpointData,
      });

      toast({
        title:
          checkpointData.direction === 1
            ? attendee?.firstName + ' ' + attendee?.lastName + ' ' + 'Inpasserad!'
            : attendee?.firstName + ' ' + attendee?.lastName + ' ' + 'Utcheckad!',
        variant: 'success',
      });

      setProccessingBadgeNo(null);

      setTimeout(() => {
        setPassage(null);
      }, 3000);
    },
    [buffer]
  );

  useEffect(() => {
    if (buffer?.length > 0 && isOnline && SignalRContext.connection?.state === 'Connected') {
      buffer.forEach(async badgeNo => {
        setProcessed([...processed, badgeNo]);
        await handlePass(badgeNo);
      });
    }
  }, [isOnline, SignalRContext.connection?.state]);

  SignalRContext.useSignalREffect(
    'onScanRejected',
    async function onScanRejected(message: string) {
      toast({ title: getLabelValue(message), variant: 'destructive' });
      setProccessingBadgeNo(null);
      setError(message);
      setTimeout(() => {
        setError(null);
      }, 3000);
    },
    []
  );

  return {
    pass: handlePass,
    passage,
    proccessingBadgeNo,
    error,
  };
}
