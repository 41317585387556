import { Button } from '@/components/ui/button';
import { useSettingsStore } from '@/stores/settingsStore';
import { getLabelValue } from '@/utils/getLabelValue';
import { Moon, Sun } from 'lucide-react';

export function ThemeSwitcher() {
  const theme = useSettingsStore(state => state.theme);
  const setTheme = useSettingsStore(state => state.setTheme);

  return (
    <div className="flex justify-center items-center gap-2">
      <Button size="sm" variant="outline" onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}>
        {theme === 'light' ? <Sun className="h-4 w-4 mr-1" /> : <Moon className="h-4 w-4 mr-1" />}
        {theme === 'light' ? getLabelValue('LightTheme') : getLabelValue('DarkTheme')}
      </Button>
    </div>
  );
}
