export function formatDate(dateString: string) {

  // Directly use the input string as a Date object (no need to manually remove timezones)
  const date = new Date(dateString);

  // Check if the date is invalid
  if (isNaN(date.getTime())) {
    return 'Invalid date';
  }

  // Format the date using the options for Swedish locale (with the correct timezone)
  const formattedDate = date.toLocaleDateString('sv-SE', {
    timeZone: 'Europe/Stockholm',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

  return formattedDate;
}
