import { Button } from '@/components/ui/button';

import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useMe } from '@/features/auth/api/get-me';
import { logout } from '@/features/auth/api/logout';
import storage from '@/utils/storage';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Separator } from '@/components/ui/separator';

export function Menu() {
  const { data: user } = useMe({});

  const deviceId = storage.getDeviceId();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="ghost" className="w-fit p-0 mr-2">
          <Avatar className="font-bold">
            <AvatarImage />
            <AvatarFallback className="bg-primary text-white uppercase">{user?.userName[0].toUpperCase()}</AvatarFallback>
          </Avatar>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-screen space-y-2">
        <span className="text-sm text-muted-foreground">Inloggad som</span>
        <div className="font-semibold text-md">{user?.userName}</div>
        <div className="text-sm italic">
          Enhets ID <code className="font-bold">{deviceId}</code>
        </div>

        <Separator />
        <Button variant="outline" className="w-full mt-4 text-destructive" onClick={logout}>
          Logga ut
        </Button>
      </PopoverContent>
    </Popover>
  );
}
