import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getLabels = (eventId: number, languageCode: string): Promise<any[]> => {
  return axios.get(`/${eventId}/label/group`, {
    params: {
      eventId,
      groupName: 'Onsite.Scanning',
      languageCode,
      type: 'SystemLabel'
    },
  });
};

type QueryFnType = typeof getLabels;

type UseLabelOptions = {
  eventId: number;
  languageCode: string;
  config?: QueryConfig<QueryFnType>;
};

export const useLabels = ({ config, eventId, languageCode }: UseLabelOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['labels'],
    queryFn: () => getLabels(eventId, languageCode),
    ...config,
  });
};
