import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { queryClient } from '@/lib/react-query';
import { useSettingsStore } from '@/stores/settingsStore';
import { useEffect, useState } from 'react';

interface ExtendedParticipationModalProps {
  onConfirm: (participants: number) => void;
  onClose: () => void;
  badgeNo: string;
}

export function ExtendedParticipationModal({ onConfirm, onClose, badgeNo }: ExtendedParticipationModalProps) {
  const [participants, setParticipants] = useState<number>(0);

  const currentEventId = useSettingsStore(state => state.eventId);

  useEffect(() => {
    if (currentEventId && [10297, 9741, 9682].includes(Number(currentEventId))) {
      debugger;
      const order = queryClient.getQueryData<any[]>(['orderlist', currentEventId])?.find(order => order.badgeNo === badgeNo);
      const orderRows = order?.orderRows?.filter((row: any) => ![10297, 9741, 9682].includes(row.eventId)) ?? [0];
      const quantity = Math.max(...orderRows.map((row: any) => row.quantity - 1)) >= 0 ? Math.max(...orderRows.map((row: any) => row.quantity - 1)) : 0;
      setParticipants(quantity);
    }
  }, []);

  const handleConfirm = () => {
    onConfirm(participants ?? 0);
  };

  return (
    <Dialog open>
      <DialogTrigger></DialogTrigger>
      <DialogContent className="[&>.close-action]:hidden max-w-80 rounded-md">
        <DialogHeader>
          <DialogTitle className="text-left"> Uttökande deltagande?</DialogTitle>
          <DialogDescription className="text-left">Skriv in antalet medföljande personer</DialogDescription>
          <div>
            <Input type="number" min={0} value={participants} onChange={event => setParticipants(Number(event.currentTarget.value))} />
          </div>
        </DialogHeader>
        <DialogFooter className="flex flex-row gap-x-2 items-center justify-end">
          <Button variant="outline" onClick={onClose}>
            Avbryt
          </Button>
          <Button onClick={handleConfirm}>OK</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
