import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { boundingBox, IDetectedBarcode, Scanner } from '@yudiel/react-qr-scanner';
import { CameraOff, QrCode, Scan } from 'lucide-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ExtendedParticipationModal } from './extended-participation-modal';
import { useSettingsStore } from '@/stores/settingsStore';
import { SignalRContext } from '@/providers/SignalRProvider';
import { errorMessages } from '../data/error-messages';
import { useOrderList } from '@/features/attendee/api/useOrderList';
import expoImage from '@/assets/expo_main.jpg';
import storage from '@/utils/storage';
import { cn } from '@/utils';
import { useHasCameraPermission } from '@/hooks/use-has-camera-permission';
import { useMe } from '@/features/auth/api/get-me';

export function Reader() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [currentCode, setCurrentCode] = useState<string>('');
  const [isScanning, setIsScanning] = useState(false);

  const timeoutRef = useRef<NodeJS.Timeout>();

  const breakoutId = useSettingsStore(state => state.breakoutId);
  const currentEventId = useSettingsStore(state => state.eventId);
  const direction = useSettingsStore(state => state.direction);
  const allowReEntry = useSettingsStore(state => state.allowReEntry);
  const onlyBooked = useSettingsStore(state => state.onlyBooked);
  const extendedParticipation = useSettingsStore(state => state.extendedParticipation);

  const isConnected = SignalRContext.connection?.state === 'Connected';

  const cameraAllowed = useHasCameraPermission();

  const { toast } = useToast();

  const { data: orderList = [], isLoading } = useOrderList({
    eventId: Number(currentEventId),
    config: {
      enabled: !!currentEventId,
    },
  });

  const { data: user } = useMe({});

  SignalRContext.useSignalREffect(
    'onScanRejected',
    (message: string) => {
      setIsScanning(false);
      toast({ variant: 'destructive', body: errorMessages[message as keyof typeof errorMessages] ?? 'Fel vid scanningx' });
    },
    []
  );

  SignalRContext.useSignalREffect(
    'onScanSuccess',
    (badgeNo: string) => {
      const attendee = orderList.find(order => order.badgeNo === badgeNo);
      setIsScanning(false);
      toast({
        variant: 'success',
        body: `
          ${attendee?.firstName} ${attendee?.lastName} har ${direction === 'In' ? 'checkat in' : 'checkat ut'}
        `,
      });
    },
    []
  );

  const handleScan = async (codes: IDetectedBarcode[], force = false, extendedParticipationCount = 0) => {
    const data = codes?.[0];
    const format = data?.format;
    const badgeNo = data?.rawValue;

    resetTimeout();

    if (format !== 'qr_code') {
      return toast({
        variant: 'destructive',
        body: 'Felaktig QR-kod',
      });
    }

    if (extendedParticipation && !force) {
      setIsModalOpen(true);
      setCurrentCode(badgeNo);
      return;
    }

    try {
      setIsScanning(true);
      debugger;

      await SignalRContext.invoke('scan', {
        eventId: currentEventId,
        badgeNo,
        passingType: direction === 'In' ? 1 : 2,
        allowReEntry,
        prinClient: null,
        breakoutId: breakoutId ?? null,
        onlyBooked,
        userId: user?.userId,
        timeStamp: +new Date(),
        add: true,
        scanDevice: storage.getDeviceId(),
        exhibitorId: 0,
        extendedParticipation: extendedParticipationCount,
      });

      setCurrentCode('');
    } catch (e) {
      console.error(e);
      toast({
        variant: 'destructive',
        body: 'Fel vid scanning..',
      });
    }
  };

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setActive(false);
      setCurrentCode('');
      setIsModalOpen(false);
    }, 1000 * 30); // 5 minutes timeout
  };

  // Handle activating the scanner
  const handleActive = () => {
    resetTimeout(); // Reset the timeout when the scanner is activated
    setActive(true);
  };

  const handleError = (error: any) => {
    if (error === 'error NotAllowedError: Permission denied') {
      toast({
        variant: 'destructive',
        body: 'Kamera åtkomst nekad',
      });
    }
  };

  // Cleanup the timeout when the component unmounts
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (active) {
      setActive(false);
    }
  }, [allowReEntry, currentEventId, direction, extendedParticipation, onlyBooked]);

  const text = useMemo(() => {
    if (!cameraAllowed) {
      return 'Kamera åtkomst nekad';
    } else if (isConnected && !currentEventId) {
      return 'Välj event för att starta';
    } else if (isConnected && !isLoading && currentEventId) {
      return 'Tryck för att scanna';
    } else {
      return 'Ingen anslutning till servern';
    }
  }, [isConnected, isLoading, currentEventId, cameraAllowed]);

  if (!active) {
    return (
      <div className="h-full w-full flex flex-col bg-black/80 text-whtie justify-center gap-8 items-center relative">
        <div
          className="w-full h-full absolute top-0 left-0 z-[-1]"
          style={{
            backgroundImage: `url(${expoImage})`,
            backgroundSize: 'cover',
          }}
        />
        <Button
          disabled={!isConnected || isLoading || !currentEventId || !cameraAllowed}
          variant="ghost"
          className="h-auto relative hover:bg-transparent hover:text-white/60 text-white"
          onClick={handleActive}
        >
          <Scan className="w-[250px] h-[250px]  absolute stroke-[0.5px]" />
          <QrCode className="w-48 h-48 " />
        </Button>
        <p className="text-center text-lg text-white font-semibold">{text}</p>
      </div>
    );
  }

  return (
    <div className="h-full w-full mx-auto flex flex-col justify-between">
      {isModalOpen && (
        <ExtendedParticipationModal
          badgeNo={currentCode}
          onClose={() => {
            setIsModalOpen(false);
            setCurrentCode('');
          }}
          onConfirm={(count: number) => {
            handleScan(
              [
                {
                  format: 'qr_code',
                  rawValue: currentCode,
                  boundingBox: { x: 0, y: 0, width: 0, height: 0 },
                  cornerPoints: [
                    { x: 0, y: 0 },
                    { x: 0, y: 0 },
                    { x: 0, y: 0 },
                    { x: 0, y: 0 },
                  ],
                },
              ],
              true,
              count
            );
            setIsModalOpen(false);
          }}
        />
      )}
      <div className={cn('h-full bg-black/80 relative')}>
        <div
          className={cn(
            'h-full w-full  border-4  z-10 bg-transparent absolute top-0 left-0 border-teal-300 pointer-events-none',
            isScanning ? 'animate-pulse border-sky-300 ' : ''
          )}
        />
        <Scanner
          formats={['qr_code']}
          onScan={handleScan}
          onError={handleError}
          components={{
            audio: true,
            onOff: true,
            torch: true,
            zoom: true,
            finder: false,
            tracker: boundingBox,
          }}
          allowMultiple
          scanDelay={2000}
        />
      </div>

      <div className="p-4 flex justify-center bg-card">
        <Button size="sm" onClick={() => setActive(false)}>
          <CameraOff className="w-4 h-4 mr-2" />
          Stäng av kamera
        </Button>
      </div>
    </div>
  );
}
