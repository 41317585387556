import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from '@/components/ui/toast';
import { CheckCircle, AlertCircle, ClipboardCheck, Info } from 'lucide-react';
import { useToast } from '@/components/ui/use-toast';

const icon = {
  destructive: <AlertCircle className="w-4 h-4 text-white" />,
  success: <CheckCircle className="w-4 h-4 text-white" />,
  clipboard: <ClipboardCheck className="w-4 h-4" />,
  info: <Info className="w-4 h-4  text-white" />,
} as {
  [key: string]: React.ReactNode;
};

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, body, ...props }) {
        return (
          <Toast key={id} {...props} className='relative'>
            <div className=" gap-2 flex items-center">
              {icon[props.variant as string]}
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && <ToastDescription>{description}</ToastDescription>}
              {body}
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
