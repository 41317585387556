import { useEvents } from '@/api/useEvents';
import { Combobox } from '@/components/ui/combobox';
import { useSettingsStore } from '@/stores/settingsStore';
import { getLabelValue } from '@/utils/getLabelValue';

export function EntranceDropdown() {
  const eventId = useSettingsStore(state => state.eventId) ?? 0;
  const breakoutId = useSettingsStore(state => state.breakoutId);

  const setBreakoutId = useSettingsStore(state => state.setBreakoutId);

  const { data: events } = useEvents({
    eventId,
    config: {
      enabled: false,
    },
  });

  const options = events
    ?.filter(event => event?.eventId === eventId || event?.parentEventId === eventId)
    ?.map(event => ({
      value: event.eventId,
      label: !event?.parentEventId ? getLabelValue('MainEntrance') : event.name,
    }));

  const handleComboxChange = (value: string) => {
    setBreakoutId(Number(value));
  };

  return (
    <Combobox
      options={options ?? []}
      value={breakoutId}
      onChange={handleComboxChange}
      disabled={false}
      classes={{
        button: 'w-64 bg-card rounded-xl  hover:opacity-85 ',
        content: 'w-64',
      }}
    />
  );
}
