import Axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from '@/config';
import storage from '@/utils/storage';
import { queryClient } from './react-query';
import { jwtDecode } from 'jwt-decode';
// import storage from '@/utils/storage';

const DISABLED_INTERCEPTORS = ['/refresh-token', '/login', '/logout'];

export async function refreshToken() {
  try {
    const response = await axios.post('/refresh-token', {
      withCredentials: true, // Ensures cookies are sent with the request
    });
    const token = response?.data;

    if (token) {
      storage.setToken(token);
    } // Store the refreshed token

    return token;
  } catch (error) {
    console.error('Error refreshing token:', error);
    storage.clearToken(); // Clear token if refresh fails
    throw error;
  }
}

async function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.getToken();

  if (!config.headers) config.headers = {};

  if (token) {
    config.headers.authorization = `${token}`;
  }

  config.headers.Accept = 'application/json';

  if (token && hasTokenExpired(token) && !DISABLED_INTERCEPTORS.includes(config.url!)) {
    try {
      const newToken = await refreshToken();
      config.headers.authorization = `${newToken}`;
    } catch (error) {
      throw error;
    }
  }

  return config;
}

export function hasTokenExpired(token: string): boolean {
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    if (!decodedToken.exp) return true;

    // Check if the token will expire within a certain time window (e.g., 5 minutes)
    return decodedToken.exp < currentTime;
  } catch (error) {
    console.error('Error checking token expiration:', error);
    return true; // Assume expired if we can't verify
  }
}

export const axios = Axios.create({
  baseURL: API_URL,
});

axios.interceptors.request.use(authRequestInterceptor as any);
axios.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    if (error.response?.status === 401) {
      storage.clearToken();
      queryClient.invalidateQueries('me');
      queryClient.refetchQueries('me');

      // window.location.href = '/login'
      // window.location.reload();

      //Get a new token with refresh token
    } else {
      return Promise.reject(error);
    }
  }
);
