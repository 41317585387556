import { Toaster } from '@/components/ui/toaster';
import * as Sentry from '@sentry/react';
import { TooltipProvider } from './components/ui/tooltip';
import { QueryClientProvider } from 'react-query';
import { queryClient } from '@/lib/react-query';
import { Checkin } from './features/mobile/components/checkin';
import { SignalRProvider } from './providers/SignalRProvider';
import storage from './utils/storage';
import { Button } from './components/ui/button';
import { AppRoutes } from './routes';
import { ThemeProvider } from './providers/theme';

function App() {
  return (
    <>
      <Sentry.ErrorBoundary
        fallback={({ error }: any) => (
          <div className="flex justify-center items-center h-screen w-full">
            <div className="flex-col flex gap-4 justify-center items-center">
              <h1 className="font-bold text-lg">
                {(() => {
                  try {
                    const message = error?.message;
                    return message;
                  } catch (error) {
                    return 'Något gick fel...';
                  }
                })()}
              </h1>

              <Button
                className="w-fit"
                onClick={() => {
                  storage.clearToken();
                  window.location.reload();
                }}
              >
                Starta om
              </Button>
            </div>
          </div>
        )}
        onError={err => {
          console.error(err);
        }}
      >
        <ThemeProvider>
          <QueryClientProvider client={queryClient} contextSharing>
            <TooltipProvider>
              <Toaster />
              <TooltipProvider>
                <SignalRProvider>
                  {window.innerWidth > 600 ? <AppRoutes /> : <Checkin />}
                  {/* TODO: fix the mobile view */}
                </SignalRProvider>
              </TooltipProvider>
            </TooltipProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </Sentry.ErrorBoundary>
    </>
  );
}

export default App;
