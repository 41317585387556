import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTrigger } from '@/components/ui/dialog';
import { AttendeeSearchResult } from '../types/attendee-search-result';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Briefcase, Calendar, Mail, Search } from 'lucide-react';
import { Separator } from '@/components/ui/separator';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { getLabelValue } from '@/utils/getLabelValue';
import { useOnsiteStats } from '../api/get-onsite-stats';
import { useSettingsStore } from '@/stores/settingsStore';
import { formatDate } from '@/utils/format-date';
import { ScrollArea } from '@/components/ui/scroll-area';

interface AttendeeDetailsDialogProps {
  attendee: AttendeeSearchResult;
}

export function AttendeeDetailsDialog({ attendee }: AttendeeDetailsDialogProps) {
  const eventId = useSettingsStore(state => state.eventId) ?? 0;
  const breakoutId = useSettingsStore(state => state.breakoutId) ?? null;

  const { data: oniteStats } = useOnsiteStats({
    eventId,
    breakoutId: breakoutId ?? undefined,
    config: {
      enabled: false,
    },
  });

  const checkins = oniteStats?.arrived?.[attendee.badgeNo]?.sort((a: Date, b: Date) => new Date(b).getTime() - new Date(a).getTime()) ?? [];
  const checkouts = oniteStats?.exit?.[attendee.badgeNo]?.sort((a: Date, b: Date) => new Date(b).getTime() - new Date(a).getTime()) ?? [];

  const groupedCheckins = checkins.reduce((acc: any, checkin: Date) => {
    const date = new Date(checkin).toDateString();
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(checkin);
    return acc;
  }, {});

  const groupedCheckouts = checkouts.reduce((acc: any, checkout: Date) => {
    const date = new Date(checkout).toDateString();
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(checkout);
    return acc;
  }, {});

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button size="sm" variant="outline">
          <Search className="h-4 w-4 mr-1" />
          Visa detaljer
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] bg-card">
        <DialogHeader>
          <div className="flex gap-4">
            <Avatar>
              <AvatarImage src={attendee?.profileImage} alt="@shadcn" />
              <AvatarFallback className='uppercase'>
                {attendee.firstName[0]}
                {attendee.lastName[0]}
              </AvatarFallback>
            </Avatar>
            <div className="space-y-2">
              <div>
                <div className="font-semibold">
                  {attendee.firstName} {attendee.lastName}
                </div>
                <div className="flex flex-col text-muted-foreground">
                  <div className="flex items-center">
                    <Mail className="h-4 w-4 mr-1" />
                    {attendee.email}
                  </div>
                  {attendee.companyName && (
                    <div className="flex items-center">
                      <Briefcase className="h-4 w-4 mr-1" />
                      {attendee.companyName}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </DialogHeader>
        <Separator />
        {checkins?.length > 0 || checkouts?.length > 0 ? (
          <div className="flex justify-between">
            <Tabs defaultValue="checkins" className="w-full">
              <TabsList className="grid w-full grid-cols-2">
                <TabsTrigger value="checkins">{getLabelValue('CheckinsTab')}</TabsTrigger>
                <TabsTrigger value="checkouts"> {getLabelValue('CheckoutTab')}</TabsTrigger>
              </TabsList>
              <TabsContent value="checkins">
                <ScrollArea className="max-h-[300px] overflow-y-auto ">
                  <div className="space-y-2">
                    {Object.entries(groupedCheckins as Record<string, Date[]>)?.map(([date, checkins]) => (
                      <div key={date} className="border p-2 px-3 rounded-md">
                        <h3 className="font-semibold flex items-center">{formatDate(date)?.substring(0, 10)}</h3>
                        {checkins.map((time: Date) => (
                          <p key={time.toString()}>{formatDate(time.toString())?.split(' ')?.[1]}</p>
                        ))}
                      </div>
                    ))}
                  </div>
                </ScrollArea>
              </TabsContent>
              <TabsContent value="checkouts">
                <ScrollArea className="max-h-[300px] overflow-y-auto">
                  {Object.entries(groupedCheckouts as Record<string, Date[]>)?.map(([date, checkouts]) => (
                    <div key={date} className="border p-2 px-3  rounded-md">
                      <h3 className="font-semibold flex items-center">{formatDate(date)?.substring(0, 10)}</h3>
                      {checkouts.map((time: Date) => (
                        <p key={time.toString()}>{formatDate(time.toString())?.split(' ')?.[1]}</p>
                      ))}
                    </div>
                  ))}
                </ScrollArea>
              </TabsContent>
            </Tabs>
          </div>
        ) : (
          <div className="text-muted-foreground">{getLabelValue('NoCheckinsOrCheckouts')}</div>
        )}
      </DialogContent>
    </Dialog>
  );
}
