import { queryClient } from '@/lib/react-query';
import storage from '@/utils/storage';
import { useEffect } from 'react';

function useEmbededAuth(expectedOrigin: string = 'https://localhost:44331', enabled: boolean = true) {
  useEffect(() => {
    // Handler for the postMessage event
    const handleMessage = (event: any) => {

      console.log('event', event);
      // Verify that the message is from the expected origin
      if (event.origin !== expectedOrigin) return;

      // Extract the token from the message data
      const token = event.data?.token;

      if (token) {
        storage.setToken(token);
        queryClient.invalidateQueries('me');
      }
    };

    if (!enabled) return;
    // Add the message event listener
    window.addEventListener('message', handleMessage, false);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('message', handleMessage, false);
  }, [expectedOrigin, enabled]);

  return null;
}

export default useEmbededAuth;
