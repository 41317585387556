import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import storage from '@/utils/storage';

export async function logout() {
  await axios.post('/logout');

  storage.clearToken();
  queryClient.invalidateQueries('me');
}
