import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { AttendeeSearchResult } from '../types/attendee-search-result';

export const getAttendeeFilterResult = (eventId: number, status: string, breakoutId?: number | null): Promise<AttendeeSearchResult[]> => {
  return axios.get(`/${eventId}/onsite/filter/${status}`, {
    params: {
      breakoutId,
    },
  });
};

type QueryFnType = typeof getAttendeeFilterResult;

type UseOptions = {
  status: string;
  breakoutId?: number | null;
  eventId: number;
  config?: QueryConfig<QueryFnType>;
};

export const useAttendeeFilterResult = ({ eventId, status, breakoutId, config }: UseOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['attendee_filter_result', `${eventId}_${breakoutId}_${status}`],
    queryFn: () => getAttendeeFilterResult(eventId, status, breakoutId),
    ...config,
  });
};
