import * as z from 'zod';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { login } from '@/features/auth/api/login';
import { useState } from 'react';
import { Spinner } from '../../../components/ui/spinner';
import { Eye, EyeOff } from 'lucide-react';
import { Card, CardDescription, CardTitle } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { SignalRContext } from '@/providers/SignalRProvider';
import { useSettingsStore } from '@/stores/settingsStore';
import { getMe } from '../api/get-me';
import storage from '@/utils/storage';

const formSchema = z.object({
  username: z.string().min(1, 'Obligatoriskt'),
  password: z.string().min(1, 'Obligatoriskt'),
});

export const LoginForm = () => {
  const [isLoading, setIloading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const setEventId = useSettingsStore(state => state.setEventId);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  async function handleSubmit(values: z.infer<typeof formSchema>) {
    try {
      form.clearErrors(); // Clear existing errors
      setIloading(true);

      //Login and get token
      await login(values);

      storage.setDeviceId();

      //Get user and set event id
      const { defaultEventId, userGuid } = await getMe();

      // await SignalRContext.invoke('onsiteLogin', {
      //   username: values.username,
      //   password: values.password,
      // });

      //Authenticate with signalR
      await SignalRContext.invoke('onsiteHandshake', {
        userGuid,
        eventId: defaultEventId,
        ConnectionId: '',
      });

      setEventId(defaultEventId);
    } catch (error) {
      //Set error and clear it if form is changning after the submit
      form.setError('root', {
        type: 'manual',
        message: 'Fel användarnamn eller lösenord',
      });
    } finally {
      setIloading(false);
      // window.location.reload();
    }
  }

  return (
    <div className="flex h-screen bg-white  w-full items-center justify-center">
      <div className="h-screen w-full flex flex-col items-center justify-center">
        <Card className="max-w-md w-full p-6 space-y-4 h-auto bg-card  border-0 shadow-none">
          <CardTitle className="text-center text-2xl font-bold">Logga in</CardTitle>
          <CardDescription className="text-left text-sm">Logga in med dina användaruppgifter för att fortsätta</CardDescription>
          <Separator />
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
              <div className="space-y-8">
                <FormField
                  control={form.control}
                  name="username"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>E-post</FormLabel>
                      <FormControl>
                        <Input placeholder="example@gmail.com" autoComplete="username" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>Lösenord</FormLabel>
                      <FormControl>
                        <div className="relative">
                          <Input {...field} type={passwordVisible ? 'text' : 'password'} className="pr-8" />
                          <Button
                            onClick={() => setPasswordVisible(!passwordVisible)}
                            variant="ghost"
                            size="xs"
                            type="button"
                            className="absolute right-1 top-1/2 -translate-y-1/2 "
                          >
                            {passwordVisible ? <EyeOff className="w-4 h-4" /> : <Eye className="w-4 h-4" />}
                          </Button>
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              {form.formState.errors?.root?.message && <div className="text-center m-2 text-sm font-medium text-destructive w-full ">{form.formState.errors?.root?.message}</div>}
              <Button disabled={isLoading} size="sm" className="w-full flex justify-center items-center" type="submit">
                <span className="flex items-center justify-center">
                  {isLoading && <Spinner className="mr-2 text-primary-foreground h-3" />}
                  {isLoading ? 'Loggar in...' : 'Logga in'}
                </span>
              </Button>
            </form>
          </Form>
        </Card>
      </div>
    </div>
  );
};
