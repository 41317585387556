import { Button } from '@/components/ui/button';

import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Switch } from '@/components/ui/switch';
import { useSettingsStore } from '@/stores/settingsStore';
import { SettingsIcon } from 'lucide-react';

export function Settings() {
  const enableReEntry = useSettingsStore(state => state.allowReEntry);
  const enableBookedOnly = useSettingsStore(state => state.onlyBooked);
  const extendedParticipation = useSettingsStore(state => state.extendedParticipation);
  const direction = useSettingsStore(state => state.direction);

  const setEnableReEntry = useSettingsStore(state => state.setAllowReEntry);
  const setEnableBookedOnly = useSettingsStore(state => state.setOnlyBooked);
  const setExtendedParticipation = useSettingsStore(state => state.setExtendedParticipation);
  const setDirection = useSettingsStore(state => state.setDirection);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="ghost">
          <SettingsIcon className="w-5 h-5" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-screen">
        <div className="grid gap-4">
          <div className="space-y-2">
            <h4 className="font-medium text-md leading-none">Inpasseringsinställningar</h4>
            <p className="text-sm text-muted-foreground">Anpassa inställningar för inpassering</p>
          </div>
          <div className="grid gap-2">
            <div className="flex flex-row items-start space-x-3 space-y-0 rounded-md border text-sm p-4">
              <Switch checked={enableBookedOnly} onCheckedChange={() => setEnableBookedOnly(!enableBookedOnly)} />
              <div className="space-y-1 leading-none">
                <label className="font-semibold">Endast bokade</label>
                <p>Endast bokade personer kan passera</p>
              </div>
            </div>
          </div>
          <div className="grid gap-2">
            <div className="flex flex-row items-start space-x-3 space-y-0 rounded-md border text-sm p-4">
              <Switch checked={enableReEntry} onCheckedChange={() => setEnableReEntry(!enableReEntry)} />
              <div className="space-y-1 leading-none">
                <label className="font-semibold">Tillåt återpassering</label>
                <p>Om denna funktion är aktiverad kan användare passera igen efter att de har passerat.</p>
              </div>
            </div>
          </div>
          <div className="grid gap-2">
            <div className="flex flex-row items-start space-x-3 space-y-0 rounded-md border text-sm p-4">
              <Switch checked={extendedParticipation} onCheckedChange={() => setExtendedParticipation(!extendedParticipation)} />
              <div className="space-y-1 leading-none">
                <label className="font-semibold">Utökat deltagande</label>
                <p>Du kan inkludera flera inpasserings registreringar vid inpassering av en person</p>
              </div>
            </div>
          </div>
          <div className="grid gap-2">
            <div className="flex flex-row items-start space-x-3 space-y-0 rounded-md border text-sm p-4">
              <Switch checked={direction === 'In'} onCheckedChange={() => setDirection(direction === 'In' ? 'Out' : 'In')} />
              <div className="space-y-1 leading-none">
                <label className="font-semibold">Inpassering?</label>
                <p>Om påslaget så är det inpassering, annars utpassering</p>
              </div>
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}
